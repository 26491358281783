import { HEADLINE_CE_NAME, HeadlineContentElementProps } from "./headlineContentElement";

/**
 * This is the global style of a CE. 
 * This style is applied on all instances of this CE
 * 
 * @param props 
 * @returns 
 */
export default function HeadlineContentElementStyleGlobal(props: HeadlineContentElementProps) {
  return (
    <style jsx global>
      {`
        :global(.${HEADLINE_CE_NAME}) {
          display: flex;
        }
      `}
    </style>
  );
}
