import HeadlineContentElementStyleScoped from "@/components/contentelements/headlineContentElement/headlineContentElementStyleScoped";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { ContentElementHeadlineStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEHeadline } from "@/types/content-elements";
import { getPbContentScopedSelector } from "@/utils/util";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import PbContentHeading from "../../content/pbContentHeading/pbContentHeading";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import HeadlineContentElementStyleGlobal from "./headlineContentElementStyleGlobal";

export interface HeadlineContentElementProps {
  content: CEHeadline;
  position: number;
  ceSettings?: ContentElementHeadlineStoreSetting;
  isLastElement: any;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const HEADLINE_CE_NAME = "headlineCE";

const HeadlineContentElement = (props: HeadlineContentElementProps) => {
  const HEADLINE_SCOPED_CLASS = useScopedClassName(HEADLINE_CE_NAME, props.content, props.position);
  const tCms = useCmsTranslation();

  const primaryHeadlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrTypographyPrimary,
    font: props.content.cfgStrFontPrimary,
    fontColor: props.content.cfgStrFontColorPrimary,
    ceSettingHeadline: props.ceSettings?.headlineheading,
    cfgHeadlineType: props.content.cfgHeadlineTypePrimary,
    ceSettings: props.ceSettings,
  });

  const secondaryHeadlineSettings = useCesStrHeadline({
    typography: props.content.cfgStrTypographySecondary,
    font: props.content.cfgStrFontSecondary,
    fontColor: props.content.cfgStrFontColorSecondary,
    ceSettingHeadline: props.ceSettings?.subheadlineheading,
    cfgHeadlineType: props.content.cfgHeadlineTypeSecondary,
    ceSettings: props.ceSettings,
  });

  return (
    <>
      <ContentElementWrapper
        ceSettings={props.ceSettings}
        scopedClassName={HEADLINE_SCOPED_CLASS}
        name={HEADLINE_CE_NAME}
        content={props.content}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Row className={clsx(HEADLINE_CE_NAME, HEADLINE_SCOPED_CLASS, "g-0")}>
          <Col xs={12}>
            <Row className={"edge-to-edge-row"}>
              <Col xs={12}>
                <PbContentHeading
                  content={props.content.primaryHeadline}
                  cmsPosition={props.position}
                  cmsField="primaryHeadline"
                  settings={primaryHeadlineSettings}
                  cmsPlaceholder={tCms("headline-primaryHeadline")}
                  textAlign={props.content.cfgHeadlineAlignPrimary}
                  scopedSelector={getPbContentScopedSelector(
                    HEADLINE_SCOPED_CLASS,
                    "headline"
                  )}
                />
              </Col>
            </Row>
            <Row className={"edge-to-edge-row"}>
              <Col xs={12} className="secondary-headline-col">
                <PbContentHeading
                  content={props.content.secondaryHeadline}
                  cmsPosition={props.position}
                  cmsField="secondaryHeadline"
                  settings={secondaryHeadlineSettings}
                  cmsPlaceholder={tCms("headline-secondaryHeadline")}
                  textAlign={props.content.cfgHeadlineAlignSecondary}
                  scopedSelector={getPbContentScopedSelector(
                    HEADLINE_SCOPED_CLASS,
                    "subheadline"
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentElementWrapper>
      <HeadlineContentElementStyleGlobal {...props} />
      <HeadlineContentElementStyleScoped
        {...props}
        scopedSelector={HEADLINE_SCOPED_CLASS}
      />
    </>
  );
};

export default React.memo(HeadlineContentElement);
