import {
  HeadlineContentElementProps
} from "./headlineContentElement";

interface HeadlineContentElementStyleScopedProps
  extends HeadlineContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function HeadlineContentElementStyleScoped(
  props: HeadlineContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        position: static;
        :global(h1.heading:after),
        :global(h2.heading:after),
        :global(.cms-content-heading.with-cms-h1:after),
        :global(.cms-content-heading.with-cms-h2:after) {
          margin-left: ${props.content.cfgHeadlineAlignPrimary === "left"
            ? "unset"
            : "auto"};
          margin-right: ${props.content.cfgHeadlineAlignPrimary === "right"
            ? "unset"
            : "auto"};
        }

        :global(.secondary-headline-col) {
          margin-top: ${props.ceSettings?.spacerHeadline
            ? props.ceSettings?.spacerHeadline + "px"
            : "0"};
        }
      }
    `}</style>
  );
}
