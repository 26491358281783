import { useAppSelector } from "@/store/store";
import { HeadingTag } from "@/types/config/enums";
import { getBootstrapTextAlignmentClass } from "@/utils/util";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { PbContentHeadingGlobalStyle } from "./pbContentHeadingStyleGlobal";
import PbContentHeadingStyleScoped from "./pbContentHeadingStyleScoped";
const CmsContentHeading = dynamic(
  () => import("@/components/cms/cmsContent/cmsContentHeading")
);

export interface PbContentHeadingProps {
  settings: PbContentHeadingSettings;
  scopedSelector?: string;
  maxLength?: number;
  contentType?: string;
  cmsField?: string;
  cmsPosition?: number;
  disableEditView?: boolean;
  content: string;
  cmsPlaceholder?: string;
  maxLengthIndicatorMargin?: string;
  hideFromHtmlDom?: boolean;
  highlightedText?: string;
  hasAMStyle?: boolean;
  textAlign?: string;
}

export interface PbContentHeadingSettings {
  headlineClasses: string;
  HeadingTag: HeadingTag;
}

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes
 *
 * @param {Object} props
 * @param {*} props.settings
 * @param {*} props.maxLength
 * @param {*} props.contentType
 * @param {*} props.cmsField
 * @param {*} props.cmsPosition
 * @param {*} props.disableEditView
 * @param {string} props.content
 * @param {*} props.cmsPlaceholder
 * @param {*} props.maxLengthIndicatorMargin
 * @param {boolean} props.hideFromHtmlDom If true no (possible SEO-relevant) HTML-Element is displayed.
 * @param {string} props.highlightedText
 * @param {boolean} props.hasAMStyle
 * @returns
 */
const PbContentHeading = (props: PbContentHeadingProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const { HeadingTag } = props.settings;

  const scopedSelector = props.scopedSelector
    ? `${props.scopedSelector}-content-heading`
    : "";

  return (
    <>
      {editView && !props.disableEditView ? (
        <CmsContentHeading {...props} scopedSelector={scopedSelector} />
      ) : props.content && !props.hideFromHtmlDom ? (
        <HeadingTag
          className={clsx(
            scopedSelector,
            "heading",
            getBootstrapTextAlignmentClass(props.textAlign ?? ""),
            props.settings.headlineClasses
          )}
        >
          {props.content}
        </HeadingTag>
      ) : null}
      <PbContentHeadingGlobalStyle />
      <PbContentHeadingStyleScoped {...props} scopedSelector={scopedSelector} />
    </>
  );
};

export default PbContentHeading;
