import { PbContentHeadingProps } from "./pbContentHeading";

interface PbContentHeadingStyleScopedProps
  extends PbContentHeadingProps {
  scopedSelector?: string;
}

export default function PbContentHeadingStyleScoped(
  props: PbContentHeadingStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }
    `}</style>
  );
}
