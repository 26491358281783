
export const PbContentHeadingGlobalStyle = () => {

  return (
    <style jsx global>{`
      .heading {
        hyphens: auto;
        word-break: break-word;

        textarea {
          padding-left: 2px;
        }

      }
    `}</style>
  );
};
